.editor-container {
  @apply text-text-primary dark:text-text-primary-dark;
  border-radius: 2px;
  position: relative;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

html.dark .editor-container {
  @apply text-text-primary-dark;
}

.editor-input {
  flex: 1;
  max-height: 120px;
  overflow: auto;
  resize: none;
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: 0 10px;
  @apply caret-text-secondary-1 dark:text-text-secondary-dark;
}

.editor-placeholder {
  @apply text-text-placeholder dark:text-text-placeholder-dark;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 0;
  left: 10px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.editor-paragraph {
  position: relative;
}

.dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.mentions-menu,
.channels-menu {
  width: 250px !important;
}

.mention:focus {
  box-shadow: rgb(180 213 255) 0px 0px 0px 2px;
  outline: none;
}

.typeahead-popover {
  @apply bg-background-primary dark:bg-background-primary-dark;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  width: auto;
}

.typeahead-popover ul {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: scroll;
}

.typeahead-popover ul::-webkit-scrollbar {
  display: none;
}

.typeahead-popover ul {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.typeahead-popover ul li {
  margin: 0;
  min-width: 180px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
}

.typeahead-popover ul li.selected {
  @apply bg-background-tetriary dark:bg-background-tetriary-dark;
}

.typeahead-popover li {
  @apply bg-background-primary text-text-primary dark:bg-background-primary-dark dark:text-text-primary-dark;
  margin: 0 8px 0 8px;
  padding: 8px;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  border-radius: 8px;
  border: 0;
}

.typeahead-popover li.active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.typeahead-popover li:first-child {
  border-radius: 8px 8px 0px 0px;
}

.typeahead-popover li:last-child {
  border-radius: 0px 0px 8px 8px;
}

.typeahead-popover li:hover {
  @apply bg-background-primary dark:bg-background-primary-dark;
}

.typeahead-popover li .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}
