@import url('@10x/foundation/assets/styles/common.css');
/* 
body {
  font-family: 'SF Pro Display', '-apple-system', 'BlinkMacSystemFont',
    'sans-serif';
} */

/* Safari fix */
@supports (-webkit-touch-callout: none) {
  .safari-fix {
    height: 100%;
  }
}

h1 {
  @apply text-h1;
}
h2 {
  @apply text-h2;
}

html,
body,
#__next {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

body,
#__next,
#root {
  flex: 1;
  position: relative;
}

#root {
  display: flex;
  flex-direction: column;
}

.nav {
  @apply hover-el text-body16SB text-text-primary underline-offset-4 dark:text-text-primary-dark;
}

.text-action-primary {
  @apply hover-el cursor-pointer text-primary-100 underline underline-offset-4 active:opacity-75  dark:text-primary-100-dark;
}

/* dropzone label */
.text-action-primary-underline {
  @apply text-action text-body16M text-primary-100 underline underline-offset-4 dark:text-primary-100-dark;
}

.disabled {
  opacity: 0.5;
}

/* ---- onboarding ---- */

.react-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.react-tabs__tab--selected {
  @apply bg-background-tetriary dark:bg-background-primary-dark dark:text-text-primary-dark;
}

.react-tabs-panel {
  @apply border-b-[1px] border-background-tetriary dark:border-background-primary-dark;
}

/* ----- onboarding step 1 community UI preview  gradients overlay---- */
.bg-gradient-opacity-top-to-bottom {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 61.98%,
    #ffffff 100%
  );
}
.dark .bg-gradient-opacity-top-to-bottom {
  background: linear-gradient(180deg, rgba(33, 33, 33, 0) 61.98%, #212121 100%);
}

/* --- dropzone. TODO: Maybe should move it to the tailwind preset? ---  */
.dropzone {
  box-sizing: content-box;
  border-radius: 5px;
  height: 80px;
  width: 100%;
  padding: 1px;
  background: linear-gradient(90deg, #c2d1d7 50%, transparent 50%),
    linear-gradient(90deg, #c2d1d7 50%, transparent 50%),
    linear-gradient(0deg, #c2d1d7 50%, transparent 50%),
    linear-gradient(0deg, #c2d1d7 50%, transparent 50%);
  background-size: 16px 1px, 16px 1px, 1px 16px, 1px 16px;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-position: 0px 0px, 100% 80px, 0px 80px, 100% 0px;
}

.dropzone.file-over {
  background: linear-gradient(90deg, #5f5cff 50%, transparent 50%),
    linear-gradient(90deg, #5f5cff 50%, transparent 50%),
    linear-gradient(0deg, #5f5cff 50%, transparent 50%),
    linear-gradient(0deg, #5f5cff 50%, transparent 50%);
  background-size: 16px 1px, 16px 1px, 1px 16px, 1px 16px;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-position: 0px 0px, 100% 80px, 0px 80px, 100% 0px;
}

.border-animation {
  animation: border-dance 4s infinite linear;
}
@keyframes border-dance {
  0% {
    background-position: 0px 0px, 438px 80px, 0px 160px, 100% 0px;
  }
  100% {
    background-position: 438px 0px, 0px 80px, 0px 0px, 100% 160px;
  }
}
/* --- !Dropzone --- */

.blobs {
  filter: url('#goo');
}

.blobs.left .thumb {
  animation: goo-dots-left 0.36s cubic-bezier(0.88, 0.28, 0.73, 0.67) both;
}
.blobs.right .thumb {
  animation: goo-dots-right 0.36s cubic-bezier(0.88, 0.28, 0.73, 0.67) both;
}
body .swiper-pagination-custom {
  bottom: 14px;
}

body .swiper-button-disabled.swiper-no-disabled-hide {
  cursor: default;
  display: flex;
  opacity: 0.5;
}

@keyframes goo-dots-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(14px);
  }
}
@keyframes goo-dots-left {
  0%,
  100% {
    transform: translateX(14px);
  }
  100% {
    transform: translateX(0);
  }
}

/* --- !Slider --- */

/* --- Popup (color-picker) --- */
@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}
#popup-root {
  width: 100%;
}
.popup-content {
  animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}
.popup-overlay {
  pointer-events: initial !important;
}
/* --- !Popup (color-picker) --- */

/* --- Build Community SVG Text */
.build-community-svg-text {
  fill: url(#gr-build-community);
}

.grad-t-build {
  background-image: linear-gradient(45deg, #ff9e00, #d964ff);
  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
/* --- !Build Community SVG Text */

@media screen and (max-width: 1180px) {
  .home-section-1-community-image-wrapper {
    width: 318px;
  }
  .home-section-1-left-dots-block {
    bottom: 184px;
  }
}

/* community card caption animation */

/* .community-card-caption-el {
  transition: all 0.22s ease-in-out;
}

.community-card-caption-bottom {
  transition: all 0.17s ease-in-out;
  opacity: 0;
}

.community-card-body:hover .community-card-caption-el {
  max-height: 310px;
}

.community-card-body:hover .community-card-caption-bottom {
  transition: all 0.22s ease-in-out 0.22s;
  opacity: 1;
} */

/* !community card caption animation */

/* --- Sidebar --- */

.sidebar-channel-item svg {
  transition: all 0.2s ease-in-out;
}
/* .sidebar-channel-item:hover svg:not(.shield-exclamation-ico) {
  fill: #1a1a1a;
} */

.sidebar-channel-item:hover .shield-exclamation-ico {
  stroke: #1a1a1a;
}

/* .sidebar-channels-wrapper {
  max-height: calc(100% - 116px);
} */

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* --- !Sidebar --- */

.ovf-anch-none * {
  overflow-anchor: none;
}

#scroll-anchor {
  overflow-anchor: auto;
  height: 1px;
  background-color: transparent;
}

/* --- suggestions --- */
.suggestion-option {
  @apply transition-all;
  display: flex;
  cursor: pointer;
  border-radius: 4px;
  padding: 6px 8px;
}
.suggestion-option:hover {
  @apply bg-hovered-selected;
}

.mention-user.mention-user-me {
  @apply rounded-[2px] bg-brand-tenPercent text-brand-pressed;
}

.mention-user.mention.mention-user-me {
  @apply rounded-[2px] bg-element-warningDim text-element-warning;
}

/* --- !suggestions --- */

/* --- parsed editor --- */
.editor-readonly .mention {
  cursor: pointer;
  @apply hover-el bg-brand-tenPercent text-brand-pressed;
}

/* --- !parsed editor --- */

/* --- keyboard button --- */
.keyboard-btn {
  padding: 4px 12px;
  border-radius: 8px;

  @apply animation-classic bg-pressed shadow-keyboard-shadow-inset dark:bg-pressed-dark dark:shadow-keyboard-shadow-inset-dark;
}
/* --- !keyboard button --- */

/* --- Jump to latest button */
.floating-btn,
.floating-btn-text-wrapper,
.floating-btn-text {
  transition: all 0.35s ease-in-out;
}
.floating-btn:hover {
  padding-left: 12px;
  padding-right: 24px;
}

.floating-btn-text {
  opacity: 0;
  white-space: nowrap;
  display: inline-block;
  margin-left: 4px;
}

.floating-btn:hover .floating-btn-text-wrapper {
  max-width: 300px;
}

.floating-btn:hover .floating-btn-text {
  opacity: 1;
}

/* --- !Jump to latest button */

/* --- Loader --- */
.loader-quatro {
  height: 32px;
  width: 32px;
  -webkit-animation: loader-quatro-1 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-quatro-1 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-quatro-1 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes loader-quatro-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-quatro::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: auto;
  margin: auto;
  width: 8px;
  height: 8px;
  background: #5f5cff;
  border-radius: 50%;
  -webkit-animation: loader-quatro-2 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-quatro-2 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-quatro-2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(24px, 0, 0) scale(0.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes loader-quatro-2 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(24px, 0, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
.loader-quatro::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: auto;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background: #5f5cff;
  border-radius: 50%;
  -webkit-animation: loader-quatro-3 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-quatro-3 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-quatro-3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(-24px, 0, 0) scale(0.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes loader-quatro-3 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(-24px, 0, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
.loader-quatro span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
}
.loader-quatro span::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  right: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background: #5f5cff;
  border-radius: 50%;
  -webkit-animation: loader-quatro-4 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-quatro-4 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-quatro-4 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(0, 24px, 0) scale(0.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes loader-quatro-4 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(0, 24px, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
.loader-quatro span::after {
  content: '';
  display: block;
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background: #5f5cff;
  border-radius: 50%;
  -webkit-animation: loader-quatro-5 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-quatro-5 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-quatro-5 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(0, -24px, 0) scale(0.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes loader-quatro-5 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(0, -24px, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
/* --- !Loader --- */

.borderred {
  @apply border-[1px] border-[red];
}

/* --- Dnd-Kit --- */
/** remove redundant debug element in the lib */
div[id^='DndLiveRegion'][role='status'] {
  display: none;
}
/* --- !Dnd-Kit --- */
